// stylelint-disable declaration-no-important, selector-no-qualifying-type

// Source: https://github.com/h5bp/main.css/blob/master/src/_print.css

// ==========================================================================
// Print styles.
// Inlined to avoid the additional HTTP request:
// https://www.phpied.com/delay-loading-your-print-css/
// ==========================================================================

@if $enable-print-styles {
  @media print {
    *,
    *::before,
    *::after {
      // Bootstrap specific; comment out `color` and `background`
      //color: $black !important; // Black prints faster
      text-shadow: none !important;
      //background: transparent !important;
      box-shadow: none !important;
    }

    a {
      &:not(.btn) {
        text-decoration: underline;
      }
    }

    // Bootstrap specific; comment the following selector out
    //a[href]::after {
    //  content: " (" attr(href) ")";
    //}

    abbr[title]::after {
      content: " (" attr(title) ")";
    }

    // Bootstrap specific; comment the following selector out
    //
    // Don't show links that are fragment identifiers,
    // or use the `javascript:` pseudo protocol
    //

    //a[href^="#"]::after,
    //a[href^="javascript:"]::after {
    // content: "";
    //}

    pre {
      white-space: pre-wrap !important;
    }
    pre,
    blockquote {
      border: $border-width solid $gray-500; // Bootstrap custom code; using `$border-width` instead of 1px
      page-break-inside: avoid;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }

    // Bootstrap specific changes start

    // Specify a size and min-width to make printing closer across browsers.
    // We don't set margin here because it breaks `size` in Chrome. We also
    // don't use `!important` on `size` as it breaks in Chrome.
    @page {
      size: $print-page-size;
    }
    body {
      min-width: $print-body-min-width !important;
    }
    .container {
      min-width: $print-body-min-width !important;
    }

    // Bootstrap components
    .navbar {
      display: none;
    }
    .badge {
      border: $border-width solid $black;
    }

    .table {
      border-collapse: collapse !important;

      td,
      th {
        background-color: $white !important;
      }
    }

    .table-bordered {
      th,
      td {
        border: 1px solid $gray-300 !important;
      }
    }

    .table-dark {
      color: inherit;

      th,
      td,
      thead th,
      tbody + tbody {
        border-color: $table-border-color;
      }
    }

    .table .thead-dark th {
      color: inherit;
      border-color: $table-border-color;
    }

    // Bootstrap specific changes end

    body,
    * {
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }

    body:before {
      background-image: none;
    }
    .container {
      min-width: 100% !important;
    }

    .report-wrapper .header-icons-wrapper,
    .report-wrapper hr,
    .report-wrapper .total-replies,
    .report-wrapper .badge-light {
      display: none;
    }

    .report-wrapper .header-buttons {
      display: none !important;
    }

    .report-wrapper .date-badge {
      display: inline-block;
      border: none;
    }

    .action-header {
      padding-top: 20px;
    }

    .report-wrapper .custom-carousel .accordion-content {
      height: auto !important;
    }

    .report-wrapper .h_40px {
      display: none;
    }

    .report-wrapper .card-title {
      position: relative;
    }

    .report-wrapper .cardToggler .compentency-description {
      // position: absolute;
      // left: 0;
      // top: 30px;
      display: block;
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      box-sizing: border-box;
      color: #303133;
      cursor: pointer;
      font-family: Barlow, sans-serif;
      line-height: 24px;
      // overflow-wrap: break-word;
      text-align: left;
      // min-width: 200px;
    }

    .report-wrapper .cardElement,
    .report-wrapper .card {
      border: unset;
    }

    .report-wrapper .print-show-more {
      display: inline !important;
      visibility: visible !important;
      content: "Show More" !important;
      pointer-events: none;
    }

    .report-wrapper .report-legend {
      display: none;
    }

    .report-wrapper .cardToggler .report-legend.inside-element {
      display: flex;
      position: absolute;
      top: -7px;
      width: 100%;
    }

    .report-wrapper .toggle-feedback {
      display: none !important;
    }

    .report-wrapper .collapsed {
      display: block !important;
    }

    .custom-carousel {
      overflow: visible;
    }

    .report-wrapper .card-element.report-card.card {
      page-break-before: always;
      page-break-inside: avoid;
    }

    .report-wrapper .carousel .slide {
      display: block !important;
      page-break-inside: avoid;
    }

    .report-wrapper .carousel .slide:nth-child(2n) {
      page-break-after: always; /* Inserisce un'interruzione dopo la seconda slide */
    }

    .report-wrapper .carousel {
      display: block !important;
    }

    .custom-carousel ul.slider.animated {
      flex-flow: wrap;
    }

    .custom-carousel li.slide {
      width: 100% !important;
      margin-bottom: 20px !important;
    }

    .custom-carousel .carousel .control-dots {
      display: none;
    }

    .report-card.card-element .card-detail {
      max-height: 1200px !important;
    }

    .report-card.card-element.report-card.card {
      break-inside: avoid;
    }

    .report-card.card-element .card-expand-toggler {
      display: none;
    }

    .report-card .to-left.slider-item .custom-progress {
      align-items: flex-start !important;
      position: relative;
      display: block;
    }

    .report-card .to-left .custom-progress-bar.bar2,
    .report-card .to-left .custom-progress-bar.bar1 {
      position: absolute;
      right: 50%;
      margin-right: 0 !important;
    }
  }
}
