// Variables
$body-color: #ffffff;
$primary-color: #ffa61e;
$secondary-color: #fff;
$border-color:#cccccc;
$primary-font: 'Barlow', sans-serif;
$secondary-font: 'Lora', serif;
$text-color: #808080;
$title-color: #757575;
$gray: #808080;
$light-gray: #fafafa;
$dark: #000;
$light: #fff;
$black: #000;
$gray-variant:#7f99ab;
$tertiary-color: #3397d9;
$card-border-radius:1.25rem;
$text-color2:#183447;//163345;
$label-color:$text-color2;

$text-muted-color:#7f99ab;
$maxcardwidth: 900px
// $icon-font: 'themefisher-font';
